<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Pesquisar contratos">
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                id="customerDocument"
                name="customerDocument"
                label="CNPJ"
                dense
                outlined
                hide-details
                clearable
                v-mask="'##.###.###/####-##'"
                v-model="filter.customerDocument"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-text-field
                id="customerName"
                name="customerName"
                label="Razão social"
                dense
                outlined
                hide-details
                clearable
                v-model="filter.customerName"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <v-autocomplete
                id="situation"
                name="situation"
                label="Status"
                dense
                outlined
                hide-details
                clearable
                :items="situation"
                v-model="filter.situation"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                id="contractCode"
                name="contractCode"
                label="Número de contrato"
                dense
                outlined
                hide-details
                clearable
                v-model="filter.contractCode"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseProductType
                id="productType"
                name="productType"
                label="Produto"
                hide-details
                clearable
                v-model="filter.productType"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                id="startEffectiveDate"
                name="startEffectiveDate"
                label="Início de vigência"
                hide-details
                v-model="filter.startEffectiveDate"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <BaseDatePicker
                id="endEffectiveDate"
                name="endEffectiveDate"
                label="Fim da vigência"
                hide-details
                v-model="filter.endEffectiveDate"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseButton
                id="btn-search"
                name="btn-search"
                type="submit"
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de contratos">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.customerName`]="{ item }">
            <span :title="item.customerName">
              {{ doTruncateText(item.customerName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.insuranceCompanyName`]="{ item }">
            <span :title="item.insuranceCompanyName">
              {{ doTruncateText(item.insuranceCompanyName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.customerDocument`]="{ item }">
            {{ formatCnpj(item.customerDocument) }}
          </template>

          <template v-slot:[`item.effectiveDate`]="{ item }">
            {{ formatDate(item.effectiveDate) }}
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  small
                  @click="edit(item)"
                  v-permission="{
                    roles: ['C1', 'C2'],
                    operator: '!=='
                  }"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>
  </v-container>
</template>

<script>
import { formatCnpj, formatDate } from '@/helpers/formatting';
import { getColor, doTruncateText, unmask } from '@/helpers/utils';
import { ContractService } from '@/services/api/contracts';

export default {
  data: () => ({
    items: [],
    totalRecords: 0,
    filter: {},
    options: {},
    headers: [
      { text: 'Cliente', value: 'customerName' },
      { text: 'Produto', value: 'productType' },
      { text: 'Início de vigência', value: 'effectiveDate' },
      { text: 'Operadora', value: 'insuranceCompanyName' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action' }
    ],
    situation: ['Ativo', 'Cancelado']
  }),

  methods: {
    formatCnpj,
    formatDate,
    getColor,
    doTruncateText,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const {
          customerDocument,
          customerName,
          situation,
          contractCode,
          productType,
          startEffectiveDate,
          endEffectiveDate
        } = this.filter;

        const contractService = new ContractService();
        const { status, data } = await contractService.getContract({
          page,
          take: itemsPerPage,
          customerDocument: unmask(customerDocument),
          customerName,
          situation,
          contractCode,
          productType,
          startEffectiveDate,
          endEffectiveDate
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      this.$router.push({ name: 'contratos-edit' });
    },

    edit(item) {
      this.$router.push({ name: 'contratos-edit', params: { id: item.id } });
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
